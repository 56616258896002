import React, { useState, useEffect } from 'react';
import Webcam from "react-webcam";
import domtoimage from 'dom-to-image';
import { Navigate } from 'react-router-dom';
import logo from '../../img/wedding-icon.png'; // with import


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadString } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "photobooth-4c6e3.firebaseapp.com",
    projectId: "photobooth-4c6e3",
    storageBucket: "photobooth-4c6e3.appspot.com",
    messagingSenderId: "971070035956",
    appId: "1:971070035956:web:c4e2ca35bc3d3a52e24ba1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);



const videoConstraints = {
    width: 1080,
    // height: 1180,
    // width: 750,
    // height: 655,
    // facingMode: "user"
};

export const WebcamCapture = () => {
    const [filmStrip, setStripImage] = useState([]); // Setting default value
    const webcamRef = React.useRef(null);
    // const [filmCount, setCount] = useState(false)
    const [photo, photoset] = useState(0)
    const [redirctTo, setRedirctTo] = useState(false); // your state value to manipulate

    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setStripImage((prevImage) => [
                ...prevImage,
                {
                    img: imageSrc
                },
            ]);
            var flipLi = document.body.querySelectorAll('.film-strip li')
            if (flipLi.length === 4) {
                setTimeout(() => {
                    (async () => {
                        const node = document.getElementById("film-strip");
                        await domtoimage.toJpeg(node);
                        const dataUrl2 = await domtoimage.toJpeg(node);
                        const link = document.createElement("a");
                        // link.href = dataUrl2;
                        // link.download = "diploma.jpg";
                        // document.body.appendChild(link);
                        // link.click();
                        photoset(dataUrl2);
                        // document.body.removeChild(link);


                        const storage = getStorage();
                        const storageRef = ref(storage, 'some-child' + Date.now());
                        const file = dataUrl2;

                        // Data URL string
                        const message4 = file;
                        uploadString(storageRef, message4, 'data_url').then((snapshot) => {
                            console.log('Uploaded a data_url string!');
                        });

                    })
                        ();
                }, 50);
            }
        });


    useEffect(() => {
        // call api or anything
        function photoClick() {
            var timesRunFirst = 0;
            var intervalFirst = window.setInterval(function () {
                timesRunFirst += 1;
                // Find all spans & remove the active class.
                const matches = document.querySelectorAll("span[data-counter]");
                for (let i = 0; i < matches.length; i++) {
                    matches[i].classList.remove("active");
                }
                // Remove Icon Active
                if (document.body.querySelector('.icon')) {
                    var elementIcon = document.body.querySelector('.icon')
                    elementIcon.classList.remove("active");
                }
                // Remove Flash Icon Active
                if (document.body.querySelector('.webcam-img')) {
                    var elementFlash = document.body.querySelector('.webcam-img')
                    elementFlash.classList.remove("active");
                }
                // Find the specific nav item & add class
                if (document.body.querySelector('span[data-counter="' + timesRunFirst + '"]')) {
                    var element = document.body.querySelector('span[data-counter="' + timesRunFirst + '"]')
                    element.classList.add("active");
                }
                // Runs 3 Times and exit function.
                if (timesRunFirst === 3) {
                    if (document.body.querySelector('.icon')) {
                        elementIcon.classList.add("active");
                    }
                    if (document.body.querySelector('.webcam-img')) {
                        elementFlash.classList.add("active");
                    }
                    capture();
                    console.log('trigger');
                    clearInterval(intervalFirst);
                }
            }, 1000);
        }

        if (document.body.querySelectorAll('.icon').length > 0) {
            setTimeout(() => {
                console.log("this is the first message")
                photoClick();
            }, 1000);
            setTimeout(() => {
                console.log("this is the second message")
                photoClick();
            }, 5000);
            setTimeout(() => {
                console.log("this is the third message")
                photoClick();
            }, 9000);
            setTimeout(() => {
                setRedirctTo(true)
            }, 13000);
        }
    }, [setRedirctTo]) // <-- empty dependency array


    const myData = {
        photoData: photo
    }

    if (redirctTo) {
        return (<Navigate to="/photo-booth-preview" state={myData} />);

    } else {
        return (
            <div className="webcam-container">
                {/* <Link className='button-next' to="/photo-booth-preview" state={myData}>Link Text</Link> */}

                <div className="webcam-img">
                    <Webcam
                        audio={false}
                        // height={780}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        // width={880}
                        videoConstraints={videoConstraints}
                        forceScreenshotSourceSize
                    />
                </div>

                <button onClick={(e) => {
                    e.preventDefault();
                    capture();
                }}
                    className="webcam-btn">Capture</button>

                <div className="film-strip-preview" id="film-strip-preview">
                    {filmStrip.map((strip, index) => (
                        <li key={index}><img alt="Photobooth" src={strip.img} /></li>
                    ))}
                </div>
                <div className='film-strip_wrapper-preview'>
                    <div className='film-strip_wrapper' id="film-strip">
                        <div className="film-strip" >
                            {filmStrip.map((strip, index) => (
                                <li key={index}><img alt="Photobooth" src={strip.img} /></li>
                            ))}
                            <li key='test'> <img alt="logo" src={logo} width="50%" /></li>

                        </div>
                    </div>
                </div>

            </div>
        );
    }



};